import { initializeApp, getApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCSKl6kC3tRFdHJJbOrAohZPVv7bL4bDa8",
  authDomain: "kickbase-analytic.firebaseapp.com",
  databaseURL: "https://kickbase-analytic-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "kickbase-analytic",
  storageBucket: "kickbase-analytic.firebasestorage.app",
  messagingSenderId: "711239456594",
  appId: "1:711239456594:web:44ceca1905dec0c24c8c8b",
  measurementId: "G-RX05XRD78G"
};

// Web Push certificate (VAPID key)
export const VAPID_KEY = "BBqOFeyIIUSaF8OZbJpHuxJFsNL66kxBgjb3y28dQr6cmh2flCtORf07r35TzehvE65I3cLHJRYeLjXcPkNhJeE";

let firebaseApp;
try {
  firebaseApp = getApp();
  console.log('Using existing Firebase app');
} catch {
  firebaseApp = initializeApp(firebaseConfig);
  console.log('Initialized new Firebase app');
}

let messagingInstance = null;
try {
  if ('serviceWorker' in navigator && 'Notification' in window) {
    messagingInstance = getMessaging(firebaseApp);
    console.log('Firebase Messaging initialized successfully');
  }
} catch (error) {
  console.warn('Messaging not supported:', error);
}

const firestore = getFirestore(firebaseApp);
console.log('Firestore initialized');

const functions = getFunctions(firebaseApp, 'europe-west1');
console.log('Firebase Functions initialized with region: europe-west1');

const analytics = getAnalytics(firebaseApp);
console.log('Analytics initialized');

console.log('Firebase initialized with config:', {
  ...firebaseConfig,
  apiKey: '***',
  messagingSenderId: '***',
  region: 'europe-west1'
});

export { 
  firebaseApp, 
  messagingInstance as messaging, 
  firestore, 
  functions,
  analytics,
  firebaseConfig
}; 