import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowUp, faArrowDown, faEquals } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import playerMappings from './data/playerMappings.json';
import { usePlayerStore } from './stores/PlayerStore';
import { observer } from 'mobx-react-lite';
import { playerService } from './api/playerService';
import ClipLoader from "react-spinners/ClipLoader";

const ComparisonBar = ({ value1, value2 }) => {
    const total = Math.max(value1 + value2, 1);
    const percentage1 = (value1 / total) * 100;
    const percentage2 = (value2 / total) * 100;

    return (
        <div className="h-2 bg-gray-600 rounded-full overflow-hidden flex">
            <motion.div 
                initial={{ width: 0 }}
                animate={{ width: `${percentage1}%` }}
                className="h-full bg-blue-500"
                transition={{ duration: 1 }}
            />
            <motion.div 
                initial={{ width: 0 }}
                animate={{ width: `${percentage2}%` }}
                className="h-full bg-red-500"
                transition={{ duration: 1 }}
            />
        </div>
    );
};

const ComparisonOverview = observer(() => {
    const playerStore = usePlayerStore();
    const [searchTerm1, setSearchTerm1] = useState('');
    const [searchTerm2, setSearchTerm2] = useState('');
    const [searchResults1, setSearchResults1] = useState([]);
    const [searchResults2, setSearchResults2] = useState([]);
    const [selectedPlayer1, setSelectedPlayer1] = useState(null);
    const [selectedPlayer2, setSelectedPlayer2] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSearch = (term, setResults) => {
        if (term.length < 2) {
            setResults([]);
            return;
        }

        const searchTerm = term.toLowerCase().trim();
        const results = [];

        // Remove duplicates by using a Map with player ID as key
        const uniquePlayers = new Map();
        if (Array.isArray(playerMappings.players)) {
            playerMappings.players.forEach(player => {
                // Only add if not already in the map and player has required fields
                if (!uniquePlayers.has(player.id) && player.id) {
                    uniquePlayers.set(player.id, {
                        ...player,
                        firstName: player.firstName || '',
                        lastName: player.lastName || '',
                        teamName: player.teamName || ''
                    });
                }
            });
        }

        uniquePlayers.forEach(player => {
            const fullName = `${player.firstName} ${player.lastName}`.toLowerCase();
            const lastName = player.lastName?.toLowerCase() || '';
            const firstName = player.firstName?.toLowerCase() || '';
            const teamName = player.teamName?.toLowerCase() || '';

            if (fullName.includes(searchTerm) || 
                lastName.includes(searchTerm) || 
                firstName.includes(searchTerm) || 
                teamName.includes(searchTerm)) {
                results.push(player);
            }
        });

        setResults(results.slice(0, 10));
    };

    const handlePlayerSelect = async (player, setSelectedPlayer, setSearchTerm, isFirstPlayer) => {
        try {
            // Set the search term to show the selected player's name
            setSearchTerm(player.name || `${player.firstName} ${player.lastName}`);
            setLoading(true);

            // Get enhanced player data
            const enhancedData = await playerService.getEnhancedPlayerData(
                playerStore.leagueId,
                player.id
            );

            if (enhancedData) {
                setSelectedPlayer(enhancedData);
            } else {
                // Fallback to basic player data if enhanced data fetch fails
                setSelectedPlayer({
                    ...player,
                    id: player.id,
                    name: player.name || `${player.firstName} ${player.lastName}`,
                    teamId: player.teamId,
                    marketValue: player.marketValue || 0,
                    totalPoints: player.totalPoints || 0,
                    averagePoints: player.averagePoints || 0,
                    goals: player.goals || 0,
                    assists: player.assists || 0,
                    playedMinutes: player.playedMinutes || 0
                });
            }
        } catch (error) {
            console.error('Error fetching player data:', error);
            // Fallback to basic player data if enhanced data fetch fails
            setSelectedPlayer({
                ...player,
                id: player.id,
                name: player.name || `${player.firstName} ${player.lastName}`,
                teamId: player.teamId,
                marketValue: player.marketValue || 0,
                totalPoints: player.totalPoints || 0,
                averagePoints: player.averagePoints || 0,
                goals: player.goals || 0,
                assists: player.assists || 0,
                playedMinutes: player.playedMinutes || 0
            });
        } finally {
            setLoading(false);
        }
    };

    const compareValues = (val1, val2) => {
        if (!val1 || !val2) return { icon: faEquals, color: 'text-gray-400', percentage: 0 };
        
        const difference = ((val1 - val2) / val2) * 100;
        if (val1 > val2) return { icon: faArrowUp, color: 'text-green-500', percentage: difference };
        if (val1 < val2) return { icon: faArrowDown, color: 'text-red-500', percentage: -difference };
        return { icon: faEquals, color: 'text-gray-400', percentage: 0 };
    };

    const metrics = [
        { 
            label: 'Marktwert', 
            getValue: (player) => player.marketValue || 0,
            format: (v) => `${v.toLocaleString()}€`,
            icon: '💰'
        },
        { 
            label: 'Punkteschnitt', 
            getValue: (player) => player.averagePoints || 0,
            format: (v) => v.toFixed(1),
            icon: '📈'
        },
        { 
            label: 'Gesamtpunkte', 
            getValue: (player) => player.totalPoints || 0,
            format: (v) => v.toFixed(0),
            icon: '🎯'
        },
        { 
            label: 'Tore', 
            getValue: (player) => player.goals || 0,
            format: (v) => v.toString(),
            icon: '⚽'
        },
        { 
            label: 'Vorlagen', 
            getValue: (player) => player.assists || 0,
            format: (v) => v.toString(),
            icon: '👟'
        },
        { 
            label: 'Spielminuten', 
            getValue: (player) => player.playedMinutes || 0,
            format: (v) => `${v}min`,
            icon: '⏱️'
        }
    ];

    return (
        <div className="p-6">
            <h2 className="text-2xl font-bold mb-6">Spielervergleich</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-gray-800 rounded-lg p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-xl font-bold">Spieler 1 auswählen</h3>
                        {selectedPlayer1 && (
                            <button
                                onClick={() => {
                                    setSelectedPlayer1(null);
                                    setSearchTerm1('');
                                }}
                                className="text-gray-400 hover:text-white"
                            >
                                ×
                            </button>
                        )}
                    </div>
                    <form onSubmit={(e) => e.preventDefault()} className="relative">
                        <label htmlFor="player1-search" className="sr-only">Spieler 1 suchen</label>
                        <div className="relative flex items-center">
                            <FontAwesomeIcon icon={faSearch} className="absolute left-3 text-gray-400 pointer-events-none" />
                            <input
                                id="player1-search"
                                type="text"
                                inputMode="search"
                                value={searchTerm1}
                                onChange={(e) => {
                                    setSearchTerm1(e.target.value);
                                    setSelectedPlayer1(null);
                                    handleSearch(e.target.value, setSearchResults1);
                                }}
                                className={`w-full bg-gray-700 text-white py-3 pl-10 pr-4 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                    selectedPlayer1 ? 'border-2 border-blue-500' : ''
                                }`}
                                placeholder="Spieler 1 suchen..."
                                autoCapitalize="off"
                            />
                        </div>
                        {searchResults1.length > 0 && !selectedPlayer1 && (
                            <div className="absolute z-10 w-full bg-gray-700 mt-1 rounded-lg shadow-xl max-h-80 overflow-y-auto">
                                <div className="py-1">
                                    {searchResults1.map(player => (
                                        <button
                                            key={player.id}
                                            onClick={() => {
                                                handlePlayerSelect(player, setSelectedPlayer1, setSearchTerm1, true);
                                                setSearchResults1([]);
                                            }}
                                            className="w-full px-4 py-2 text-left hover:bg-gray-600 flex items-center space-x-3"
                                        >
                                            <img 
                                                src={`https://kickbase.b-cdn.net/pool/playersbig/${player.id}.png`}
                                                alt={player.firstName + ' ' + player.lastName}
                                                className="w-10 h-10 rounded-full flex-shrink-0"
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${player.teamId}.png`;
                                                }}
                                            />
                                            <div>
                                                <div className="font-semibold text-white">{player.firstName} {player.lastName}</div>
                                                <div className="text-sm text-gray-400">{player.teamName}</div>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </form>
                </div>

                <div className="bg-gray-800 rounded-lg p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-xl font-bold">Spieler 2 auswählen</h3>
                        {selectedPlayer2 && (
                            <button
                                onClick={() => {
                                    setSelectedPlayer2(null);
                                    setSearchTerm2('');
                                }}
                                className="text-gray-400 hover:text-white"
                            >
                                ×
                            </button>
                        )}
                    </div>
                    <form onSubmit={(e) => e.preventDefault()} className="relative">
                        <label htmlFor="player2-search" className="sr-only">Spieler 2 suchen</label>
                        <div className="relative flex items-center">
                            <FontAwesomeIcon icon={faSearch} className="absolute left-3 text-gray-400 pointer-events-none" />
                            <input
                                id="player2-search"
                                type="text"
                                inputMode="search"
                                value={searchTerm2}
                                onChange={(e) => {
                                    setSearchTerm2(e.target.value);
                                    setSelectedPlayer2(null);
                                    handleSearch(e.target.value, setSearchResults2);
                                }}
                                className={`w-full bg-gray-700 text-white py-3 pl-10 pr-4 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                    selectedPlayer2 ? 'border-2 border-blue-500' : ''
                                }`}
                                placeholder="Spieler 2 suchen..."
                                autoCapitalize="off"
                            />
                        </div>
                        {searchResults2.length > 0 && !selectedPlayer2 && (
                            <div className="absolute z-10 w-full bg-gray-700 mt-1 rounded-lg shadow-xl max-h-80 overflow-y-auto">
                                <div className="py-1">
                                    {searchResults2.map(player => (
                                        <button
                                            key={player.id}
                                            onClick={() => {
                                                handlePlayerSelect(player, setSelectedPlayer2, setSearchTerm2, false);
                                                setSearchResults2([]);
                                            }}
                                            className="w-full px-4 py-2 text-left hover:bg-gray-600 flex items-center space-x-3"
                                        >
                                            <img 
                                                src={`https://kickbase.b-cdn.net/pool/playersbig/${player.id}.png`}
                                                alt={player.firstName + ' ' + player.lastName}
                                                className="w-10 h-10 rounded-full flex-shrink-0"
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${player.teamId}.png`;
                                                }}
                                            />
                                            <div>
                                                <div className="font-semibold text-white">{player.firstName} {player.lastName}</div>
                                                <div className="text-sm text-gray-400">{player.teamName}</div>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </div>

            {selectedPlayer1 && selectedPlayer2 && (
                <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-gray-800 rounded-lg shadow-xl overflow-hidden mt-6"
                >
                    <div className="p-6">
                        <div className="flex justify-between items-start mb-8">
                            <div className="relative w-1/3">
                                <div className="relative">
                                    <img 
                                        src={`https://kickbase.b-cdn.net/pool/playersbig/${selectedPlayer1.id}.png`}
                                        alt={selectedPlayer1.firstName + ' ' + selectedPlayer1.lastName}
                                        className="w-32 h-32 object-cover rounded-lg mx-auto"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${selectedPlayer1.teamId}.png`;
                                        }}
                                    />
                                </div>
                                <div className="text-center mt-2">
                                    <div className="text-xl font-bold text-white">{selectedPlayer1.firstName} {selectedPlayer1.lastName}</div>
                                    <div className="text-sm text-gray-400">{selectedPlayer1.teamName}</div>
                                </div>
                            </div>

                            <div className="text-3xl font-bold text-white self-center">VS</div>

                            <div className="relative w-1/3">
                                <div className="relative">
                                    <img 
                                        src={`https://kickbase.b-cdn.net/pool/playersbig/${selectedPlayer2.id}.png`}
                                        alt={selectedPlayer2.firstName + ' ' + selectedPlayer2.lastName}
                                        className="w-32 h-32 object-cover rounded-lg mx-auto"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${selectedPlayer2.teamId}.png`;
                                        }}
                                    />
                                </div>
                                <div className="text-center mt-2">
                                    <div className="text-xl font-bold text-white">{selectedPlayer2.firstName} {selectedPlayer2.lastName}</div>
                                    <div className="text-sm text-gray-400">{selectedPlayer2.teamName}</div>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-4">
                            {metrics.map((metric, idx) => {
                                const value1 = metric.getValue(selectedPlayer1);
                                const value2 = metric.getValue(selectedPlayer2);
                                const comparison = compareValues(value1, value2);
                                
                                return (
                                    <motion.div 
                                        key={idx}
                                        initial={{ opacity: 0, y: 10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: idx * 0.1 }}
                                        className="relative"
                                    >
                                        <div className="text-center mb-2">
                                            <span className="text-xl mr-2">{metric.icon}</span>
                                            <span className="text-gray-400 font-medium">{metric.label}</span>
                                        </div>

                                        <div className="bg-gray-700 p-4 rounded-lg">
                                            <div className="flex justify-between items-center mb-2">
                                                <motion.span 
                                                    className="text-white font-semibold"
                                                    initial={{ scale: 0.9 }}
                                                    animate={{ scale: 1 }}
                                                    whileHover={{ scale: 1.1 }}
                                                >
                                                    {metric.format(value1)}
                                                </motion.span>
                                                <div className="flex flex-col items-center">
                                                    <FontAwesomeIcon 
                                                        icon={comparison.icon} 
                                                        className={`${comparison.color} text-lg`}
                                                    />
                                                    {comparison.percentage !== 0 && (
                                                        <span className={`text-xs ${comparison.color}`}>
                                                            {Math.abs(comparison.percentage).toFixed(1)}%
                                                        </span>
                                                    )}
                                                </div>
                                                <motion.span 
                                                    className="text-white font-semibold"
                                                    initial={{ scale: 0.9 }}
                                                    animate={{ scale: 1 }}
                                                    whileHover={{ scale: 1.1 }}
                                                >
                                                    {metric.format(value2)}
                                                </motion.span>
                                            </div>
                                            
                                            <div className="relative pt-1">
                                                <ComparisonBar 
                                                    value1={value1} 
                                                    value2={value2} 
                                                />
                                            </div>
                                        </div>
                                    </motion.div>
                                );
                            })}
                        </div>
                    </div>
                </motion.div>
            )}
        </div>
    );
});

export default ComparisonOverview; 