import { messaging, VAPID_KEY } from '../firebase/config';
import { getToken, onMessage } from 'firebase/messaging';

class NotificationService {
  constructor() {
    this.messaging = messaging;
  }

  async requestPermission() {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const token = await this.getToken();
        return token;
      }
      throw new Error('Notification permission denied');
    } catch (error) {
      console.error('Error requesting notification permission:', error);
      throw error;
    }
  }

  async getToken() {
    try {
      const currentToken = await getToken(this.messaging, {
        vapidKey: VAPID_KEY
      });
      if (currentToken) {
        return currentToken;
      }
      throw new Error('No registration token available');
    } catch (error) {
      console.error('Error getting token:', error);
      throw error;
    }
  }

  onMessageListener() {
    return new Promise((resolve) => {
      onMessage(this.messaging, (payload) => {
        resolve(payload);
      });
    });
  }
}

export const notificationService = new NotificationService(); 