import React, { useState, useEffect, useRef } from 'react';
import { 
    getLeagueOverview, 
    getLeagueRanking, 
    getManagerTransfers,
    getLeagueFeed,
    getManagerDashboard,
    getManagerSquad,
    getManagerPerformance,
    getPlayerDetails,
    getPlayerMarketValueHistory
} from './api';
import { playerService } from './api/playerService';
import { useTransferVoting } from './hooks/useTransferVoting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faArrowTrendUp, faArrowTrendDown } from '@fortawesome/free-solid-svg-icons';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const LeagueOverview = () => {
    const [loading, setLoading] = useState(true);
    const [leagueData, setLeagueData] = useState(null);
    const [rankings, setRankings] = useState([]);
    const [error, setError] = useState(null);
    const [leagueId, setLeagueId] = useState(null);
    const [currentDay, setCurrentDay] = useState(null);
    const [selectedManager, setSelectedManager] = useState(null);
    const [transfers, setTransfers] = useState([]);
    const [loadingTransfers, setLoadingTransfers] = useState(false);
    const [activities, setActivities] = useState([]);
    const [selectedManagerData, setSelectedManagerData] = useState(null);
    const [playerDetails, setPlayerDetails] = useState({});
    const [playerTransactions, setPlayerTransactions] = useState({});
    const [managerStats, setManagerStats] = useState({});
    const [activeTransferTab, setActiveTransferTab] = useState('all');
    const [selectedSeason, setSelectedSeason] = useState(null);
    const { votes, userVotes, loading: votingLoading, error: votingError, isOnline, handleVote } = useTransferVoting(leagueId);
    const [squadAnalysis, setSquadAnalysis] = useState({});
    const [comparedManagers, setComparedManagers] = useState([]);
    const [showCumulativePoints, setShowCumulativePoints] = useState(true);
    const [expandedRows, setExpandedRows] = useState({});
    const [performanceData, setPerformanceData] = useState([]);
    const [loadingPerformance, setLoadingPerformance] = useState(false);
    const chartRef = useRef(null);
    const [historicalPoints] = useState({
        '989867': 16685,  // Büchse
        '273436': 15611,  // Kai Dtmrs
        '990245': 15571,  // matze
        '1404233': 15337, // Jarno
        '990497': 14499,  // Fab
        '1033250': 14484  // MolloW
    });

    // Function to toggle row expansion
    const toggleRowExpansion = (managerId) => {
        setExpandedRows(prev => ({
            ...prev,
            [managerId]: !prev[managerId]
        }));
    };

    // Update selectedSeason when selectedManagerData changes
    useEffect(() => {
        if (selectedManagerData?.performance?.it?.length > 0) {
            setSelectedSeason(selectedManagerData.performance.it[selectedManagerData.performance.it.length - 1].sid);
        }
    }, [selectedManagerData]);

    useEffect(() => {
        const storedLeagueId = localStorage.getItem('selectedLeagueId');
        if (storedLeagueId) {
            setLeagueId(storedLeagueId);
        }
    }, []);

    useEffect(() => {
        const fetchLeagueData = async () => {
            try {
                setLoading(true);
                setError(null);
                
                const [overviewData, rankingData, feedData] = await Promise.all([
                    getLeagueOverview(leagueId),
                    getLeagueRanking(leagueId),
                    getLeagueFeed(leagueId)
                ]);

                console.log('Overview Data:', overviewData);
                console.log('Ranking Data:', rankingData);
                console.log('Feed Data:', feedData);

                setLeagueData(overviewData);
                setCurrentDay(rankingData?.day || null);
                setRankings(rankingData?.us || []);
                
                // Select first manager by default
                if (rankingData?.us?.length > 0) {
                    const firstManager = rankingData.us[0];
                    fetchManagerDetails(firstManager.i);
                }

                // Fetch all manager transfers after getting the rankings
                if (rankingData?.us) {
                    await fetchAllManagerTransfers(rankingData.us);
                }

                const feedItems = feedData?.af || [];
                setActivities(feedItems);

                // Fetch player details for each transfer
                const playerIds = new Set(feedItems
                    .filter(item => item.data?.pi)
                    .map(item => item.data.pi));
                
                for (const playerId of playerIds) {
                    fetchPlayerDetails(playerId);
                }
            } catch (error) {
                console.error('Error fetching league data:', error);
                setError('Fehler beim Laden der Liga-Daten');
            } finally {
                setLoading(false);
            }
        };

        if (leagueId) {
            fetchLeagueData();
        }
    }, [leagueId]);

    useEffect(() => {
        if (!activities || !Array.isArray(activities)) return;

        // Track all player transactions
        const transactions = {};

        activities.forEach(activity => {
            if (!activity.data?.pi) return;

            const playerId = activity.data.pi;
            const playerName = activity.data.pn;
            const price = activity.data.trp;
            const date = activity.dt;
            const buyer = activity.data.byr;
            const seller = activity.data.slr;

            if (!transactions[playerId]) {
                transactions[playerId] = {
                    name: playerName,
                    history: []
                };
            }

            // Add transaction to history
            transactions[playerId].history.push({
                type: buyer ? 'buy' : 'sell',
                price,
                date,
                buyer,
                seller
            });
        });

        setPlayerTransactions(transactions);
    }, [activities]);

    const fetchManagerDetails = async (managerId) => {
        try {
            setLoadingTransfers(true);
            const [
                transferData,
                dashboardData,
                squadData,
                performanceData
            ] = await Promise.all([
                getManagerTransfers(leagueId, managerId),
                getManagerDashboard(leagueId, managerId),
                getManagerSquad(leagueId, managerId),
                getManagerPerformance(leagueId, managerId)
            ]);

            console.log('Transfer Data:', transferData);
            console.log('Dashboard Data:', dashboardData);
            console.log('Squad Data:', squadData);
            console.log('Performance Data:', performanceData);

            setTransfers(transferData || []);
            setSelectedManager(managerId);
            setSelectedManagerData({
                dashboard: dashboardData,
                squad: squadData,
                performance: performanceData
            });
        } catch (error) {
            console.error('Error fetching manager details:', error);
        } finally {
            setLoadingTransfers(false);
        }
    };

    const fetchPlayerDetails = async (playerId) => {
        try {
            const details = await getPlayerDetails(leagueId, playerId);
            setPlayerDetails(prev => ({
                ...prev,
                [playerId]: details
            }));
        } catch (error) {
            console.error('Error fetching player details:', error);
        }
    };

    const formatNumber = (number) => {
        if (number === undefined || number === null) return '-';
        return new Intl.NumberFormat('de-DE').format(number);
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const getTransferTypeInfo = (activity) => {
        if (!activity.data) return {};
        
        if (activity.data.byr) {
            return {
                type: 'buy',
                user: activity.data.byr,
                price: activity.data.trp
            };
        } else if (activity.data.slr) {
            return {
                type: 'sell',
                user: activity.data.slr,
                price: activity.data.trp
            };
        }
        return {};
    };

    const getPriceComparison = (activity) => {
        const playerDetail = playerDetails[activity.data?.pi];
        if (!playerDetail?.mv || !activity.data?.trp) return null;

        const difference = activity.data.trp - playerDetail.mv;
        const percentage = (difference / playerDetail.mv) * 100;

        return {
            difference,
            percentage,
            isOverpaid: difference > 0
        };
    };

    const renderTransactionInfo = (activity) => {
        const playerId = activity.data?.pi;
        if (!playerId || !playerTransactions[playerId]) return null;

        const transactions = playerTransactions[playerId].history;
        const currentTransaction = transactions.find(t => 
            t.date === activity.dt && 
            (t.buyer === activity.data.byr || t.seller === activity.data.slr)
        );

        if (!currentTransaction) return null;

        if (currentTransaction.type === 'sell') {
            const buyTransaction = transactions.find(t => 
                t.type === 'buy' && 
                t.buyer === activity.data.slr &&
                new Date(t.date) < new Date(activity.dt)
            );

            if (buyTransaction) {
                const profit = currentTransaction.price - buyTransaction.price;
                const profitPercentage = (profit / buyTransaction.price) * 100;
                const daysBetween = Math.floor((new Date(activity.dt) - new Date(buyTransaction.date)) / (1000 * 60 * 60 * 24));

                return (
                    <div className="text-sm mt-2">
                        <div className="text-gray-400">
                            Gekauft für: {formatNumber(buyTransaction.price)} € ({formatDate(buyTransaction.date)})
                        </div>
                        <div className={profit >= 0 ? 'text-green-400' : 'text-red-400'}>
                            {profit >= 0 ? 'Gewinn' : 'Verlust'}: {formatNumber(Math.abs(profit))} € 
                            ({profitPercentage > 0 ? '+' : ''}{profitPercentage.toFixed(1)}%)
                        </div>
                        <div className="text-gray-400">
                            Haltedauer: {daysBetween} Tage
                        </div>
                    </div>
                );
            }
        }

        return null;
    };

    const renderManagerStats = () => (
        <div className="bg-gray-800 rounded-lg p-6 shadow-lg col-span-1 md:col-span-2">
            <h3 className="text-xl font-bold mb-4 text-custom-orange">Transfer Statistiken</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {Object.entries(managerStats)
                    .sort(([,a], [,b]) => b.totalProfit - a.totalProfit)
                    .map(([manager, stats]) => (
                        <div key={manager} className="bg-gray-700 p-4 rounded-lg">
                            <div className="font-semibold text-lg mb-2">{manager}</div>
                            <div className="space-y-2">
                                <div className="text-sm">
                                    <span className="text-gray-400">Gesamtgewinn:</span>{' '}
                                    <span className={stats.totalProfit >= 0 ? 'text-green-400' : 'text-red-400'}>
                                        {formatNumber(stats.totalProfit)} €
                                    </span>
                                </div>
                                <div className="text-sm">
                                    <span className="text-gray-400">Anzahl abgeschlossener Transfers:</span>{' '}
                                    {stats.transactions}
                                </div>
                                {stats.bestDeal && (
                                    <div className="text-sm">
                                        <div className="text-gray-400">Bester Deal:</div>
                                        <div className="pl-2">
                                            <div>{stats.bestDeal.playerName}</div>
                                            <div className="text-green-400">
                                                +{formatNumber(stats.bestDeal.profit)} € 
                                                ({stats.bestDeal.profitPercentage.toFixed(1)}%)
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );

    // Update the rankings section
    const getRankingWithProfits = () => {
        return sortedRankings.map(rank => {
            const stats = managerStats[rank.i];
            let totalPoints = rank.sp || 0;
            
            // Only add historical points for league 1269435
            if (leagueId === '1269435') {
                const historicalPointsForManager = historicalPoints[rank.i] || 14000;
                totalPoints += historicalPointsForManager;
            }
            
            return {
                ...rank,
                tv: stats?.teamValue || rank.tv,
                sp: totalPoints,
                transferProfit: stats?.totalProfit || 0,
                transferCount: stats?.transactions || 0,
                bestDeal: stats?.bestDeal || null,
                worstDeal: stats?.worstDeal || null,
                completedDeals: stats?.completedDeals || []
            };
        }).sort((a, b) => b.sp - a.sp);
    };

    const getMarketAnalysis = () => {
        let totalOverpaid = 0;
        let totalOverpaidPercentage = 0;
        let overPaidCount = 0;
        let totalTransactions = 0;
        let totalTransferVolume = 0;
        let mostExpensiveTransfer = null;
        let quickestProfit = null;
        let highestProfitPerDay = null;
        let highestOverpayment = null;

        activities.forEach(activity => {
            if (!activity.data?.pi || !activity.data?.trp) return;

            totalTransactions++;
            totalTransferVolume += activity.data.trp;

            // Track most expensive transfer
            if (!mostExpensiveTransfer || activity.data.trp > mostExpensiveTransfer.price) {
                mostExpensiveTransfer = {
                    playerName: activity.data.pn,
                    price: activity.data.trp,
                    date: activity.dt,
                    buyer: activity.data.byr,
                    seller: activity.data.slr
                };
            }

            const priceComparison = getPriceComparison(activity);
            if (priceComparison?.isOverpaid) {
                totalOverpaid += priceComparison.difference;
                totalOverpaidPercentage += priceComparison.percentage;
                overPaidCount++;

                // Track highest overpayment percentage
                if (!highestOverpayment || priceComparison.percentage > highestOverpayment.percentage) {
                    highestOverpayment = {
                        playerName: activity.data.pn,
                        price: activity.data.trp,
                        marketValue: playerDetails[activity.data.pi]?.mv,
                        difference: priceComparison.difference,
                        percentage: priceComparison.percentage,
                        date: activity.dt,
                        buyer: activity.data.byr
                    };
                }
            }

            // Calculate profit speed metrics
            if (activity.data.slr) {
                const transactions = playerTransactions[activity.data.pi]?.history;
                if (transactions) {
                    const buyTransaction = transactions.find(t => 
                        t.type === 'buy' && 
                        t.buyer === activity.data.slr &&
                        new Date(t.date) < new Date(activity.dt)
                    );

                    if (buyTransaction) {
                        const profit = activity.data.trp - buyTransaction.price;
                        const days = Math.max(1, Math.floor((new Date(activity.dt) - new Date(buyTransaction.date)) / (1000 * 60 * 60 * 24)));
                        const profitPerDay = profit / days;

                        if (profit > 0) {
                            if (!quickestProfit || days < quickestProfit.days) {
                                quickestProfit = {
                                    playerName: activity.data.pn,
                                    profit,
                                    days,
                                    profitPerDay
                                };
                            }

                            if (!highestProfitPerDay || profitPerDay > highestProfitPerDay.profitPerDay) {
                                highestProfitPerDay = {
                                    playerName: activity.data.pn,
                                    profit,
                                    days,
                                    profitPerDay
                                };
                            }
                        }
                    }
                }
            }
        });

        return {
            averageOverpaid: overPaidCount ? totalOverpaid / overPaidCount : 0,
            averageOverpaidPercentage: overPaidCount ? totalOverpaidPercentage / overPaidCount : 0,
            totalTransactions,
            averageTransferPrice: totalTransactions ? totalTransferVolume / totalTransactions : 0,
            totalTransferVolume,
            mostExpensiveTransfer,
            quickestProfit,
            highestProfitPerDay,
            highestOverpayment
        };
    };

    const fetchAllManagerTransfers = async (managers) => {
        const allTransfers = {};
        const profitStats = {};

        try {
            // Log all manager IDs and names
            console.log('Manager IDs and Names:');
            managers.forEach(manager => {
                console.log(`${manager.n}: ID = ${manager.i}`);
            });

            // Initialize stats for all managers first
            managers.forEach(manager => {
                profitStats[manager.i] = {
                    managerId: manager.i,
                    managerName: manager.n,
                    totalProfit: 0,
                    transactions: 0,
                    bestDeal: null,
                    worstDeal: null,
                    completedDeals: [],
                    activePurchases: [],
                    allTransfers: [], // New array to store all transfers
                    purchases: {},
                    squad: [],
                    teamValue: 0
                };
            });

            // Fetch transfers and squad data for each manager
            const managerPromises = managers.map(async manager => {
                try {
                    const [transfers, squadData] = await Promise.all([
                        getManagerTransfers(leagueId, manager.i),
                        getManagerSquad(leagueId, manager.i)
                    ]);

                    if (squadData) {
                        profitStats[manager.i].squad = squadData.it || [];
                        profitStats[manager.i].teamValue = (squadData.it || []).reduce((sum, player) => sum + (player.mv || 0), 0);
                    }

                    if (!transfers?.it || !Array.isArray(transfers.it)) {
                        console.log(`No transfers found for manager ${manager.n}`);
                        return;
                    }
                    
                    allTransfers[manager.i] = transfers.it;
                    
                    // Store all transfers
                    profitStats[manager.i].allTransfers = transfers.it.map(transfer => ({
                        ...transfer,
                        type: transfer.tty === 1 ? 'buy' : 'sell',
                        date: new Date(transfer.dt)
                    }));

                    // Group transfers by player ID for profit calculation
                    const playerTransfers = {};
                    transfers.it.forEach(transfer => {
                        if (!playerTransfers[transfer.pi]) {
                            playerTransfers[transfer.pi] = [];
                        }
                        playerTransfers[transfer.pi].push({
                            ...transfer,
                            date: new Date(transfer.dt)
                        });
                    });

                    // Process each player's transfers for profit calculation
                    for (const [playerId, playerTransfers] of Object.entries(playerTransfers)) {
                        // Sort transfers by date
                        playerTransfers.sort((a, b) => a.date - b.date);

                        // Count all transfers
                        profitStats[manager.i].transactions = transfers.it.length;

                        // Process buy/sell pairs for profit calculation
                        for (let i = 0; i < playerTransfers.length; i++) {
                            const current = playerTransfers[i];
                            const next = playerTransfers[i + 1];

                            if (current.tty === 1 && current.trp > 0) { // This is a purchase
                                if (next?.tty === 2) { // Has matching sale
                                    const profit = next.trp - current.trp;
                                    const days = Math.floor((next.date - current.date) / (1000 * 60 * 60 * 24));
                                    const profitPerDay = profit / Math.max(1, days);
                                    const profitPercentage = (profit / current.trp) * 100;

                                    const dealInfo = {
                                        playerName: current.pn,
                                        buyPrice: current.trp,
                                        sellPrice: next.trp,
                                        buyDate: current.date,
                                        sellDate: next.date,
                                        profit,
                                        profitPercentage,
                                        days,
                                        profitPerDay
                                    };

                                    profitStats[manager.i].completedDeals.push(dealInfo);
                                    profitStats[manager.i].totalProfit += profit;

                                    if (!profitStats[manager.i].bestDeal || profit > profitStats[manager.i].bestDeal.profit) {
                                        profitStats[manager.i].bestDeal = dealInfo;
                                    }
                                    if (!profitStats[manager.i].worstDeal || profit < profitStats[manager.i].worstDeal.profit) {
                                        profitStats[manager.i].worstDeal = dealInfo;
                                    }

                                    i++; // Skip the sale transfer
                                } else {
                                    // This is an active purchase (no matching sale yet)
                                    try {
                                        const playerDetails = await getPlayerDetails(leagueId, playerId);
                                        const currentMarketValue = playerDetails?.mv || 0;
                                        const overpayment = current.trp - currentMarketValue;
                                        const overpaymentPercentage = currentMarketValue > 0 ? (overpayment / currentMarketValue) * 100 : 0;

                                        profitStats[manager.i].activePurchases.push({
                                            playerName: current.pn,
                                            price: current.trp,
                                            date: current.date,
                                            marketValue: currentMarketValue,
                                            overpayment: Math.max(0, overpayment),
                                            overpaymentPercentage: Math.max(0, overpaymentPercentage)
                                        });
                                    } catch (error) {
                                        console.error(`Error fetching player details for ${current.pn}:`, error);
                                    }
                                }
                            }
                        }
                    }
                } catch (error) {
                    console.error(`Error processing transfers for manager ${manager.n}:`, error);
                }
            });

            await Promise.all(managerPromises);
            console.log('All manager transfers:', allTransfers);
            console.log('Profit statistics:', profitStats);
            setManagerStats(profitStats);

        } catch (error) {
            console.error('Error fetching all manager transfers:', error);
        }
    };

    const analyzeSquad = async (managerId, squad) => {
        if (!squad?.it) return null;

        // Count positions like in TeamOverview.js
        const countPositions = (players) => {
            const counts = {
                Torwart: 0,
                Abwehr: 0,
                Mittelfeld: 0,
                Sturm: 0
            };

            players.forEach(player => {
                switch(player.pos) {
                    case 1:
                        counts.Torwart++;
                        break;
                    case 2:
                        counts.Abwehr++;
                        break;
                    case 3:
                        counts.Mittelfeld++;
                        break;
                    case 4:
                        counts.Sturm++;
                        break;
                }
            });

            return counts;
        };

        const positions = countPositions(squad.it);

        const analysis = {
            squadSize: squad.it.length,
            positionDistribution: {
                1: positions.Torwart,
                2: positions.Abwehr,
                3: positions.Mittelfeld,
                4: positions.Sturm
            },
            valueByPosition: {
                1: 0,
                2: 0,
                3: 0,
                4: 0
            },
            pointsByPosition: {
                1: 0,
                2: 0,
                3: 0,
                4: 0
            },
            totalValue: 0,
            totalPoints: 0,
            pointsPerValue: 0,
            positions: positions,
            positionNeeds: {
                Torwart: {
                    current: positions.Torwart,
                    required: 3,
                    needed: Math.max(0, 3 - positions.Torwart)
                },
                Abwehr: {
                    current: positions.Abwehr,
                    required: 5,
                    needed: Math.max(0, 5 - positions.Abwehr)
                },
                Mittelfeld: {
                    current: positions.Mittelfeld,
                    required: 5,
                    needed: Math.max(0, 5 - positions.Mittelfeld)
                },
                Sturm: {
                    current: positions.Sturm,
                    required: 3,
                    needed: Math.max(0, 3 - positions.Sturm)
                }
            }
        };

        squad.it.forEach(player => {
            // Value by position
            analysis.valueByPosition[player.pos] = (analysis.valueByPosition[player.pos] || 0) + player.mv;
            
            // Points by position
            analysis.pointsByPosition[player.pos] = (analysis.pointsByPosition[player.pos] || 0) + (player.t || 0);
            
            // Totals
            analysis.totalValue += player.mv;
            analysis.totalPoints += (player.t || 0);
        });

        // Calculate points per million
        analysis.pointsPerValue = analysis.totalPoints / (analysis.totalValue / 1000000);

        return analysis;
    };

    const renderSquadComparison = () => {
        if (!selectedManager || !squadAnalysis || !squadAnalysis[selectedManager]) return null;

        const currentAnalysis = squadAnalysis[selectedManager];
        if (!currentAnalysis || !currentAnalysis.positionNeeds) return null;

        const positionLabels = {
            Torwart: 'Torwart',
            Abwehr: 'Abwehr',
            Mittelfeld: 'Mittelfeld',
            Sturm: 'Sturm'
        };

        return (
            <div className="bg-gray-700 p-4 rounded-lg">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Squad Size and Requirements */}
                    <div className="bg-gray-800 p-4 rounded-lg">
                        <h4 className="text-sm font-semibold mb-3">Kadergröße & Anforderungen</h4>
                        <div className="space-y-3">
                            <div className="flex items-center justify-between">
                                <div>
                                    <div className="text-2xl font-bold">{currentAnalysis.squadSize || 0}</div>
                                    <div className="text-sm text-gray-400">Spieler gesamt</div>
                                </div>
                            </div>

                            {/* Position Requirements */}
                            <div className="space-y-2 mt-4">
                                {Object.keys(positionLabels).map(position => {
                                    const stats = currentAnalysis.positionNeeds[position];
                                    if (!stats) return null;

                                    return (
                                        <div key={position} className="flex justify-between items-center">
                                            <span className="text-gray-400">{positionLabels[position]}</span>
                                            <div className="flex items-center gap-2">
                                                <span className={stats.needed > 0 ? 'text-red-400' : 'text-green-400'}>
                                                    {stats.current}/{stats.required}
                                                </span>
                                                {stats.needed > 0 && (
                                                    <span className="text-xs text-red-400">
                                                        ({stats.needed} benötigt)
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (selectedManager && selectedManagerData?.squad) {
            const analysis = analyzeSquad(selectedManager, selectedManagerData.squad);
            setSquadAnalysis(prevState => ({
                ...prevState,
                [selectedManager]: analysis
            }));
        }
    }, [selectedManager, selectedManagerData]);

    // Helper function to get random color for each manager's chart line
    const getManagerColor = (index) => {
        const colors = ['#f97316', '#22c55e', '#3b82f6', '#ec4899', '#f59e0b', '#8b5cf6'];
        return colors[index % colors.length];
    };

    // Initialize comparedManagers with all managers except the selected one
    useEffect(() => {
        if (rankings.length > 0) {
            const allManagerIds = rankings.map(m => m.i);
            if (selectedManager) {
                setComparedManagers(allManagerIds.filter(id => id !== selectedManager));
            } else {
                setComparedManagers(allManagerIds);
            }
        }
    }, [rankings, selectedManager]);

    useEffect(() => {
        const fetchAllPerformanceData = async () => {
            if (!rankings.length || !leagueId) return;
            
            setLoadingPerformance(true);
            try {
                const performancePromises = rankings.map(async manager => {
                    try {
                        const data = await getManagerPerformance(leagueId, manager.i);
                        return {
                            n: manager.n,
                            performance: data
                        };
                    } catch (error) {
                        console.error(`Error fetching performance for manager ${manager.n}:`, error);
                        return null;
                    }
                });

                const results = await Promise.all(performancePromises);
                const validResults = results.filter(r => r && r.performance?.it);
                setPerformanceData(validResults);

                // Set initial season if not already set
                if (validResults.length > 0 && !selectedSeason) {
                    const firstManager = validResults[0];
                    const latestSeason = firstManager.performance.it[firstManager.performance.it.length - 1];
                    setSelectedSeason(latestSeason.sid);
                }
            } catch (error) {
                console.error('Error fetching performance data:', error);
            } finally {
                setLoadingPerformance(false);
            }
        };

        fetchAllPerformanceData();
    }, [rankings, leagueId]);

    const renderPerformanceCharts = () => {
        if (loadingPerformance) {
            return (
                <div className="bg-gray-800 rounded-lg p-4 shadow-lg">
                    <div className="flex justify-center items-center h-[300px]">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-custom-orange"></div>
                    </div>
                </div>
            );
        }

        if (performanceData.length === 0) return null;

        const firstManager = performanceData[0];
        const seasonData = firstManager.performance.it.find(season => season.sid === selectedSeason);
        if (!seasonData) return null;

        // Calculate cumulative points for an array of matchday points
        const calculateCumulativePoints = (matchdayPoints) => {
            let sum = 0;
            return matchdayPoints.map(points => sum += points);
        };

        // Prepare datasets for all managers
        const datasets = performanceData.map((manager, index) => {
            const managerSeasonData = manager.performance.it.find(season => season.sid === selectedSeason);
            if (!managerSeasonData?.it) return null;

            return {
                label: manager.n,
                data: showCumulativePoints 
                    ? calculateCumulativePoints(managerSeasonData.it.map(day => day.mdp))
                    : managerSeasonData.it.map(day => day.mdp),
                borderColor: getManagerColor(index),
                backgroundColor: `${getManagerColor(index)}1a`,
                tension: 0.4,
                fill: true
            };
        }).filter(Boolean);

        const pointsData = {
            labels: seasonData.it?.map(day => `Spieltag ${day.day}`) || [],
            datasets
        };

        const chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        boxWidth: 20,
                        padding: 15,
                        font: {
                            size: 14
                        },
                        usePointStyle: true,
                        pointStyle: 'circle'
                    },
                    onClick: (e, legendItem, legend) => {
                        const index = legendItem.datasetIndex;
                        const ci = legend.chart;
                        if (ci.isDatasetVisible(index)) {
                            ci.hide(index);
                            legendItem.hidden = true;
                        } else {
                            ci.show(index);
                            legendItem.hidden = false;
                        }
                    }
                },
                tooltip: {
                    callbacks: {
                        label: (context) => `${context.dataset.label}: ${context.raw} Punkte`
                    }
                },
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'x'
                    },
                    zoom: {
                        wheel: {
                            enabled: true
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'x',
                        drag: {
                            enabled: true,
                            backgroundColor: 'rgba(247, 115, 22, 0.2)',
                            borderColor: '#f97316'
                        }
                    }
                }
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        maxRotation: 0,
                        autoSkip: true,
                        maxTicksLimit: 6,
                        font: {
                            size: 12
                        }
                    }
                },
                y: {
                    grid: {
                        color: 'rgba(255, 255, 255, 0.1)'
                    },
                    ticks: {
                        font: {
                            size: 12
                        }
                    }
                }
            },
            interaction: {
                intersect: false,
                mode: 'index'
            }
        };

        return (
            <div className="bg-gray-800 rounded-lg p-4 shadow-lg">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <h3 className="text-xl font-bold text-custom-orange">Performance Verlauf</h3>
                        
                        <div className="flex flex-wrap gap-2">
                            {/* Points Type Switch */}
                            <button
                                onClick={() => setShowCumulativePoints(!showCumulativePoints)}
                                className={`px-3 py-1.5 rounded-lg text-xs transition-colors ${
                                    showCumulativePoints 
                                        ? 'bg-custom-orange text-white' 
                                        : 'bg-gray-700 text-gray-400 hover:text-white'
                                }`}
                            >
                                {showCumulativePoints ? 'Gesamtpunkte' : 'Spieltagspunkte'}
                            </button>

                            {/* Season Selection */}
                            <select
                                className="bg-gray-700 text-white px-3 py-1.5 rounded-lg appearance-none pr-8 cursor-pointer hover:bg-gray-600 transition-colors text-xs"
                                value={selectedSeason || ''}
                                onChange={(e) => setSelectedSeason(e.target.value)}
                            >
                                {firstManager.performance.it.map((season) => (
                                    <option key={season.sid} value={season.sid}>
                                        {season.sn}
                                    </option>
                                ))}
                            </select>

                            {/* Reset Zoom Button */}
                            <button
                                onClick={() => {
                                    if (chartRef.current) {
                                        chartRef.current.resetZoom();
                                    }
                                }}
                                className="px-3 py-1.5 rounded-lg text-xs bg-gray-700 text-gray-400 hover:text-white transition-colors"
                            >
                                Reset Zoom
                            </button>
                        </div>
                    </div>

                    <div className="bg-gray-700 p-4 rounded-lg">
                        <div className="h-[300px] sm:h-[400px]">
                            <Line 
                                ref={chartRef}
                                data={pointsData} 
                                options={chartOptions} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-[200px]">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-4 text-center">
                <div className="bg-red-500 text-white p-4 rounded-lg">
                    {error}
                </div>
            </div>
        );
    }

    if (!leagueData || !Array.isArray(rankings)) {
        return (
            <div className="p-4 text-center">
                <div className="bg-yellow-500 text-white p-4 rounded-lg">
                    Keine Liga-Daten verfügbar
                </div>
            </div>
        );
    }

    // Sort rankings by season points (sp)
    const sortedRankings = [...rankings].sort((a, b) => b.sp - a.sp);
    const topRankings = sortedRankings.slice(0, 5);

    // Calculate average team value
    const avgTeamValue = rankings.reduce((sum, user) => sum + user.tv, 0) / rankings.length;

    return (
        <div className="p-0">
            {!isOnline && (
                <div className="bg-yellow-500/20 text-yellow-400 px-4 py-2 mb-4 rounded-lg text-sm">
                    You are currently offline. Some features may be limited.
                </div>
            )}
            {votingError && (
                <div className="bg-red-500/20 text-red-400 px-4 py-2 mb-4 rounded-lg text-sm">
                    {votingError}
                </div>
            )}
            
            <div className="grid grid-cols-1 gap-4">
                {/* Recent Activities */}
                <div className="bg-gray-800 rounded-lg p-3 shadow-lg">
                    <h3 className="text-lg font-bold mb-2 text-custom-orange">Transferaktivitäten</h3>
                    
                    {/* Transfer Tabs */}
                    <div className="flex mb-2 bg-gray-700 rounded-lg p-1">
                        <button
                            className={`flex-1 py-1.5 px-3 rounded-lg text-sm transition-all duration-200 ${
                                activeTransferTab === 'all' 
                                    ? 'bg-custom-orange text-white' 
                                    : 'text-gray-400 hover:text-white'
                            }`}
                            onClick={() => setActiveTransferTab('all')}
                        >
                            Alle
                        </button>
                        <button
                            className={`flex-1 py-1.5 px-3 rounded-lg text-sm transition-all duration-200 ${
                                activeTransferTab === 'buy' 
                                    ? 'bg-custom-orange text-white' 
                                    : 'text-gray-400 hover:text-white'
                            }`}
                            onClick={() => setActiveTransferTab('buy')}
                        >
                            Kauf
                        </button>
                        <button
                            className={`flex-1 py-1.5 px-3 rounded-lg text-sm transition-all duration-200 ${
                                activeTransferTab === 'sell' 
                                    ? 'bg-custom-orange text-white' 
                                    : 'text-gray-400 hover:text-white'
                            }`}
                            onClick={() => setActiveTransferTab('sell')}
                        >
                            Verkauf
                        </button>
                    </div>

                    {/* Transfer List */}
                    <div className="space-y-2 max-h-[400px] overflow-y-auto">
                        {Array.isArray(activities) && activities.length > 0 ? (
                            activities
                                .filter(activity => {
                                    if (!activity.data?.pi) return false;
                                    const transferInfo = getTransferTypeInfo(activity);
                                    if (activeTransferTab === 'all') return true;
                                    if (activeTransferTab === 'buy') return transferInfo.type === 'buy';
                                    if (activeTransferTab === 'sell') return transferInfo.type === 'sell';
                                    return true;
                                })
                                .map((activity, index) => {
                                    const transferInfo = getTransferTypeInfo(activity);
                                    const priceComparison = getPriceComparison(activity);
                                    const transferVotes = votes[activity.i] || { up: 0, down: 0 };
                                    
                                    return (
                                        <div key={activity.i} className="bg-gray-700 p-4 rounded-lg hover:bg-gray-600 transition-all duration-200">
                                            <div className="flex flex-col h-full">
                                                {/* Top section - Main info */}
                                                <div className="flex justify-between items-start gap-4 mb-3">
                                                    {/* Left side - Player info */}
                                                    <div className="flex-grow">
                                                        <div className="font-semibold text-base">
                                                            {activity.data.pn}
                                                        </div>
                                                        <div className="text-xs text-gray-400">
                                                            {transferInfo.type === 'buy' ? 'Gekauft von' : 'Verkauft von'} {transferInfo.user}
                                                        </div>
                                                        {renderTransactionInfo(activity)}
                                                    </div>

                                                    {/* Right side - Price info */}
                                                    <div className="text-right">
                                                        <div className={`text-base font-bold ${priceComparison?.isOverpaid ? 'text-red-400' : 'text-white'}`}>
                                                            {formatNumber(transferInfo.price)} €
                                                        </div>
                                                        <div className="text-xs text-gray-400">
                                                            {formatDate(activity.dt)}
                                                        </div>
                                                        {playerDetails[activity.data.pi]?.mv && (
                                                            <div className="text-xs text-gray-400">
                                                                MW: {formatNumber(playerDetails[activity.data.pi].mv)} €
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Bottom section - Voting and overpay info */}
                                                <div className="flex justify-between items-center mt-2 pt-3 border-t border-gray-600">
                                                    {/* Voting buttons */}
                                                    <div className="flex items-center gap-3">
                                                        <button 
                                                            onClick={() => handleVote(activity.i, true)}
                                                            className={`flex items-center gap-2 px-3 py-1.5 rounded-lg transition-all duration-200 group
                                                                ${userVotes[activity.i]?.isUpvote 
                                                                    ? 'bg-green-500/20 text-green-400' 
                                                                    : 'hover:bg-green-500/20'}`}
                                                        >
                                                            <FontAwesomeIcon 
                                                                icon={faThumbsUp} 
                                                                className={`${userVotes[activity.i]?.isUpvote 
                                                                    ? 'text-green-400' 
                                                                    : 'text-gray-400 group-hover:text-green-400'} transition-colors`}
                                                                size="sm"
                                                            />
                                                            <span className={`text-xs font-medium ${userVotes[activity.i]?.isUpvote 
                                                                ? 'text-green-400' 
                                                                : 'text-gray-400 group-hover:text-green-400'} transition-colors`}>
                                                                {votes[activity.i]?.up || 0}
                                                            </span>
                                                        </button>
                                                        <button 
                                                            onClick={() => handleVote(activity.i, false)}
                                                            className={`flex items-center gap-2 px-3 py-1.5 rounded-lg transition-all duration-200 group
                                                                ${userVotes[activity.i]?.isUpvote === false 
                                                                    ? 'bg-red-500/20 text-red-400' 
                                                                    : 'hover:bg-red-500/20'}`}
                                                        >
                                                            <FontAwesomeIcon 
                                                                icon={faThumbsDown} 
                                                                className={`${userVotes[activity.i]?.isUpvote === false 
                                                                    ? 'text-red-400' 
                                                                    : 'text-gray-400 group-hover:text-red-400'} transition-colors`}
                                                                size="sm"
                                                            />
                                                            <span className={`text-xs font-medium ${userVotes[activity.i]?.isUpvote === false 
                                                                ? 'text-red-400' 
                                                                : 'text-gray-400 group-hover:text-red-400'} transition-colors`}>
                                                                {votes[activity.i]?.down || 0}
                                                            </span>
                                                        </button>
                                                    </div>

                                                    {/* Overpay badge */}
                                                    {priceComparison && priceComparison.isOverpaid && (
                                                        <div className="text-xs px-3 py-1.5 bg-red-500/20 text-red-400 rounded-lg font-medium">
                                                            +{priceComparison.percentage.toFixed(1)}% über MW
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                        ) : (
                            <div className="text-center text-gray-400">
                                Keine Transfers verfügbar
                            </div>
                        )}
                    </div>
                </div>

                {/* Rankings */}
                <div className="bg-gray-800 rounded-lg p-3 shadow-lg">
                    <h3 className="text-lg font-bold mb-2 text-custom-orange">Aktuelle Rangliste</h3>
                    <div className="space-y-2 max-h-[600px] overflow-y-auto">
                        {getRankingWithProfits().map((rank, index) => {
                            const isExpanded = expandedRows[rank.i];
                            return (
                                <div key={rank.i} className="mb-2">
                                    <div 
                                        className={`bg-gray-700 p-4 ${isExpanded ? 'rounded-t-lg' : 'rounded-lg'} cursor-pointer hover:bg-gray-600 transition-all duration-200`}
                                        onClick={() => toggleRowExpansion(rank.i)}
                                    >
                                        <div className="flex justify-between items-start">
                                            <div className="flex items-center">
                                                <div className="text-lg font-bold text-custom-orange mr-3">
                                                    #{index + 1}
                                                </div>
                                                <div>
                                                    <div className="text-base font-semibold">{rank.n}</div>
                                                    <div className="text-xs text-gray-400">
                                                        {formatNumber(rank.sp)} Punkte
                                                    </div>
                                                    {rank.transferCount > 0 && (
                                                        <div className={`text-xs ${rank.transferProfit >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                                                            Profit: {rank.transferProfit >= 0 ? '+' : ''}{formatNumber(rank.transferProfit)} €
                                                        </div>
                                                    )}
                                                    {managerStats[rank.i]?.activePurchases?.some(p => p.overpayment > 0) && (
                                                        <div className="text-xs text-red-400">
                                                            Überzahlt: {formatNumber(managerStats[rank.i].activePurchases.reduce((sum, p) => sum + p.overpayment, 0))} €
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <div className="text-sm">
                                                    <span className="text-gray-400">Teamwert:</span> {formatNumber(rank.tv)} €
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isExpanded && (
                                        <div className="bg-gray-700 p-4 rounded-b-lg -mt-2 border-l-4 border-custom-orange">
                                            <div className="space-y-4">
                                                <div>
                                                    <h4 className="text-sm font-semibold mb-2">Transfer Statistiken</h4>
                                                    <div className="space-y-1">
                                                        <div className="text-sm">
                                                            <span className="text-gray-400">Gesamtgewinn:</span>{' '}
                                                            <span className={rank.transferProfit >= 0 ? 'text-green-400' : 'text-red-400'}>
                                                                {formatNumber(rank.transferProfit)} €
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Transfer History Section */}
                                                {(managerStats[rank.i]?.allTransfers?.length > 0) && (
                                                    <div>
                                                        <div className="flex justify-between items-center mb-4">
                                                            <h4 className="text-sm font-semibold">Transferhistorie</h4>
                                                            <button
                                                                onClick={() => {
                                                                    setExpandedRows(prev => ({
                                                                        ...prev,
                                                                        [`${rank.i}_showCompleted`]: !prev[`${rank.i}_showCompleted`]
                                                                    }));
                                                                }}
                                                                className={`px-3 py-1 rounded-lg text-xs transition-colors ${
                                                                    expandedRows[`${rank.i}_showCompleted`]
                                                                        ? 'bg-custom-orange text-white'
                                                                        : 'bg-gray-600 text-gray-300 hover:bg-gray-500'
                                                                }`}
                                                            >
                                                                {expandedRows[`${rank.i}_showCompleted`] ? 'Nur abgeschlossen' : 'Alle Transfers'}
                                                            </button>
                                                        </div>
                                                        <div className="space-y-2 max-h-[300px] overflow-y-auto">
                                                            {managerStats[rank.i].allTransfers
                                                                .filter(transfer => {
                                                                    if (expandedRows[`${rank.i}_showCompleted`]) {
                                                                        // For completed transfers, find matching buy/sell pairs
                                                                        if (transfer.type === 'sell') {
                                                                            const buyTransfer = managerStats[rank.i].allTransfers.find(
                                                                                t => t.type === 'buy' && 
                                                                                t.pi === transfer.pi && 
                                                                                t.date < transfer.date
                                                                            );
                                                                            return buyTransfer !== undefined;
                                                                        }
                                                                        return false;
                                                                    }
                                                                    return true; // Show all transfers when not filtered
                                                                })
                                                                .sort((a, b) => b.date - a.date)
                                                                .map((transfer, index) => {
                                                                    // For completed transfers, calculate profit
                                                                    let profit = null;
                                                                    let profitPercentage = null;
                                                                    if (expandedRows[`${rank.i}_showCompleted`] && transfer.type === 'sell') {
                                                                        const buyTransfer = managerStats[rank.i].allTransfers.find(
                                                                            t => t.type === 'buy' && 
                                                                            t.pi === transfer.pi && 
                                                                            t.date < transfer.date
                                                                        );
                                                                        if (buyTransfer) {
                                                                            profit = transfer.trp - buyTransfer.trp;
                                                                            profitPercentage = (profit / buyTransfer.trp) * 100;
                                                                        }
                                                                    }

                                                                    return (
                                                                        <div key={index} className="bg-gray-800 p-3 rounded-lg">
                                                                            <div className="flex justify-between items-start">
                                                                                <div>
                                                                                    <div className="font-semibold">{transfer.pn}</div>
                                                                                    <div className="text-xs text-gray-400">
                                                                                        {transfer.type === 'buy' ? 'Gekauft' : 'Verkauft'} am {formatDate(transfer.date)}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="text-right">
                                                                                    <div className="text-sm">
                                                                                        {formatNumber(transfer.trp)} €
                                                                                    </div>
                                                                                    {!expandedRows[`${rank.i}_showCompleted`] && transfer.type === 'buy' && (
                                                                                        <div className="text-xs">
                                                                                            {transfer.mv && (
                                                                                                <div className="text-gray-400">
                                                                                                    MW: {formatNumber(transfer.mv)} €
                                                                                                </div>
                                                                                            )}
                                                                                            {transfer.mv && transfer.trp > transfer.mv && (
                                                                                                <div className="text-red-400">
                                                                                                    +{formatNumber(transfer.trp - transfer.mv)} € ({((transfer.trp - transfer.mv) / transfer.mv * 100).toFixed(1)}%)
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                    {expandedRows[`${rank.i}_showCompleted`] && profit !== null && (
                                                                                        <div className={`text-xs ${profit >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                                                                                            {profit >= 0 ? '+' : ''}{formatNumber(profit)} € ({profitPercentage >= 0 ? '+' : ''}{profitPercentage.toFixed(1)}%)
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                )}

                                                <div>
                                                    <h4 className="text-sm font-semibold mb-2">Performance</h4>
                                                    <div className="space-y-1">
                                                        <div className="text-sm">
                                                            <span className="text-gray-400">Gesamtpunkte:</span> {formatNumber(rank.sp)}
                                                        </div>
                                                        <div className="text-sm">
                                                            <span className="text-gray-400">Teamwert:</span> {formatNumber(rank.tv)} €
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/* Bottom Stats Section */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                    {/* League Stats */}
                    <div className="bg-gray-800 rounded-lg p-3 shadow-lg">
                        <h3 className="text-lg font-bold mb-2 text-custom-orange">Liga Statistiken</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p className="text-gray-400 text-xs">Spieltag</p>
                                <p className="text-sm">
                                    {currentDay || '-'}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray-400 text-xs">Gesamtwert aller Teams</p>
                                <p className="text-sm">
                                    {formatNumber(rankings.reduce((sum, user) => sum + user.tv, 0))} €
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Market Analysis */}
                    <div className="bg-gray-800 rounded-lg p-3 shadow-lg">
                        <h3 className="text-lg font-bold mb-2 text-custom-orange">Markt Analyse</h3>
                        {(() => {
                            const analysis = getMarketAnalysis();
                            return (
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <p className="text-gray-400 text-xs">Ø Überzahlung</p>
                                        <p className="text-sm text-red-400">
                                            {formatNumber(analysis.averageOverpaid)} € (+{analysis.averageOverpaidPercentage.toFixed(1)}%)
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-400 text-xs">Ø Transferpreis</p>
                                        <p className="text-sm">
                                            {formatNumber(analysis.averageTransferPrice)} €
                                        </p>
                                    </div>
                                </div>
                            );
                        })()}
                    </div>

                    {/* Performance Metrics */}
                    <div className="bg-gray-800 rounded-lg p-3 shadow-lg">
                        <h3 className="text-lg font-bold mb-2 text-custom-orange">Performance Vergleich</h3>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div>
                                <p className="text-gray-400 text-sm">Höchste Punktzahl</p>
                                <p className="text-lg">
                                    {topRankings[0]?.sp ? formatNumber(topRankings[0].sp) : '-'}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray-400 text-sm">Durchschnittlicher Teamwert</p>
                                <p className="text-lg">
                                    {formatNumber(avgTeamValue)} €
                                </p>
                            </div>
                            <div>
                                <p className="text-gray-400 text-sm">Aktive Manager</p>
                                <p className="text-lg">
                                    {rankings.length}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Squad Overview */}
                <div className="bg-gray-800 rounded-lg p-4 shadow-lg col-span-1 md:col-span-2">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-xl font-bold text-custom-orange">Kader</h3>
                        
                        {/* Main Manager Selection */}
                        <div className="relative">
                            <select
                                className="bg-gray-700 text-white px-4 py-2 rounded-lg appearance-none pr-8 cursor-pointer hover:bg-gray-600 transition-colors"
                                value={selectedManager || ''}
                                onChange={(e) => {
                                    const managerId = e.target.value;
                                    if (managerId) {
                                        fetchManagerDetails(managerId);
                                    } else {
                                        setSelectedManager(null);
                                    }
                                }}
                            >
                                <option value="">Manager auswählen</option>
                                {rankings.map((manager) => (
                                    <option key={manager.i} value={manager.i}>
                                        {manager.n}
                                    </option>
                                ))}
                            </select>
                            <div className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-400">
                                ▼
                            </div>
                        </div>
                    </div>

                    {!selectedManager ? (
                        <div className="text-center text-gray-400 py-8">
                            Wähle einen Manager aus dem Dropdown-Menü aus, um dessen Kader zu sehen.
                        </div>
                    ) : loadingTransfers ? (
                        <div className="flex justify-center py-4">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-custom-orange"></div>
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                            {selectedManagerData?.squad?.it
                                ?.sort((a, b) => b.mv - a.mv)
                                ?.map((player) => (
                                    <div key={player.pi} className="bg-gray-700 p-3 rounded-lg">
                                        <div className="flex gap-3">
                                            {/* Player Image */}
                                            <div className="w-14 h-14 rounded-lg overflow-hidden bg-gray-800 flex-shrink-0">
                                                <img 
                                                    src={`https://kickbase.b-cdn.net/pool/playersbig/${player.pi}.png`} 
                                                    alt={player.pn}
                                                    className="w-full h-full object-cover"
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = 'https://kickbase.b-cdn.net/pool/playersbig/fallback.png';
                                                    }}
                                                />
                                            </div>
                                            
                                            {/* Player Info */}
                                            <div className="flex-grow min-w-0">
                                                <div className="flex items-center justify-between">
                                                    <div className="font-semibold truncate mr-2">{player.pn}</div>
                                                    <div className="text-xs text-gray-400 flex-shrink-0">
                                                        {(() => {
                                                            switch(player.pos) {
                                                                case 1: return 'TW';
                                                                case 2: return 'ABW';
                                                                case 3: return 'MIT';
                                                                case 4: return 'STU';
                                                                default: return 'Unknown';
                                                            }
                                                        })()}
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-between mt-1">
                                                    <div className="text-xs">{formatNumber(player.mv)} €</div>
                                                    {player.st === 1 && (
                                                        <FontAwesomeIcon 
                                                            icon={player.mvt === 1 ? faArrowTrendUp : faArrowTrendDown}
                                                            className={player.mvt === 1 ? 'text-green-400' : 'text-red-400'}
                                                            size="sm"
                                                        />
                                                    )}
                                                </div>
                                                <div className="flex items-center justify-between mt-1">
                                                    <div className="text-xs text-gray-400">{player.ap || 0} Pkt</div>
                                                </div>
                                                {player.prc && player.prc > player.mv && (
                                                    <div className="text-xs text-red-400 mt-1">
                                                        +{formatNumber(player.prc - player.mv)} € über MW
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>

                {/* Performance Chart */}
                <div className="mt-8">
                    {selectedManagerData?.performance && renderPerformanceCharts()}
                </div>
            </div>
        </div>
    );
};

export default LeagueOverview; 