import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { playerStoreInstance } from './stores/PlayerStore';
import { runInAction } from 'mobx';
import { login } from './api';
import { useNavigate } from 'react-router-dom';
import background from './background.png';
import overviewImg from './overview.jpg';
import virtualSalesImg from './virtualSales.jpg';
import transferMarketImg from './transferMarket.jpg';
import scoutingListImg from './scoutingList.jpg';
import topGamesImg from './topGames.jpg';
import budgetCalculatorImg from './budgetCalculator.jpg';
import marketTrendsImg from './marketTrends.jpg';
import playerAlternativesImg from './playerAlternatives.jpg';
import TermsAndConditions from './TermsAndConditions';
import Disclaimer from './Disclaimer';
import PrivacyPolicy from './PrivacyPolicy';
import Modal from 'react-modal';
import { CookieProvider } from './CookieContext';
import logo from './analyticlogo.png';

const TOKEN_EXPIRY_DAYS = 3;

const Feature = ({ img, title, description, index }) => {
    const containerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { delay: 0.2, duration: 0.8 }
        }
    };

    return (
        <motion.div className={`flex flex-col items-center`}
            variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1, transition: { delay: index * 0.1 } }
            }}
        >
            <motion.div
                className="mb-6 flex flex-col items-center text-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={containerVariants}
            >
                <motion.h3
                    className="text-2xl md:text-3xl font-bold mb-3 text-center"
                    variants={containerVariants}
                >
                    {title}
                </motion.h3>
                <motion.p
                    className="text-md md:text-lg text-center"
                    variants={containerVariants}
                >
                    {description}
                </motion.p>

                <div className="w-full lg:w-1/4 mb-8 md:mb-0">
                    <motion.img 
                        src={img} 
                        alt={title} 
                        className="rounded-lg shadow-xl"
                        variants={{
                            hidden: { scale: 0.95, opacity: 0 },
                            visible: { opacity: 1, transition: { delay: index * 0.1 } }
                        }}
                    />
                </div>
            </motion.div>
        </motion.div>
    );
};

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const store = playerStoreInstance;
    const [isTermsOpen, setTermsOpen] = useState(false);
    const [isDisclaimerOpen, setDisclaimerOpen] = useState(false);
    const [isPrivacyOpen, setPrivacyOpen] = useState(false);

    const features = [
        {
            id: 1,
            title: "Dein Kader im Überblick",
            description: "Beobachte Marktwertsteigerungen auf den Cent genau, sogar Wochen zurück.",
            img: overviewImg,
        },
        {
            id: 2,
            title: "Virtueller Spielerhandel",
            description: "Verkaufe deine Spieler virtuell, um zu sehen, wie du bis zum Anpfiff wieder schwarze Zahlen schreibst.",
            img: virtualSalesImg,
        },
        {
            id: 3,
            title: "Marktbeobachtung in Echtzeit",
            description: "Schaue dir Minutengenau an, welche Spieler auf dem Transfermarkt ablaufen und reagiere schneller als andere.",
            img: transferMarketImg,
        },
        {
            id: 4,
            title: "Scoutingliste",
            description: "Füge Spieler zu deiner persönlichen Scoutingliste hinzu und behalte sie im Blick.",
            img: scoutingListImg,
        },
        {
            id: 5,
            title: "Topspiele Überblick",
            description: "Sieh dir die Topspiele deiner Kontrahenten und in der Liga an, um strategische Entscheidungen zu treffen.",
            img: topGamesImg,
        },
        {
            id: 6,
            title: "Budget Planung",
            description: "Schau dir an, was du noch ausgeben kannst. Keine komplizierte Formeln - Kickly macht alles für dich.",
            img: budgetCalculatorImg,
        },
        {
            id: 7,
            title: "Marktwert Trends",
            description: "Schau dir historische Marktwertverläufe an und erkenne frühzeitig Trends. KI-basierte Prognosen helfen dir dabei.",
            img: marketTrendsImg,
        },
        {
            id: 8,
            title: "Spieler Alternativen",
            description: "Finde Alternativen zu deinen Spielern, sowohl auf dem Markt als auch von anderen Spielern, und baue dir so deinen Schattenkader.",
            img: playerAlternativesImg,
        },
    ];

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: '#1F2937',
            color: 'white',
            borderRadius: '10px',
            border: 'none',
            maxWidth: '80%',
            maxHeight: '80%',
            padding: '20px',
            overflow: 'auto'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
        }
    };

    useEffect(() => {
        // Check if we have a valid token
        const token = localStorage.getItem('token');
        const tokenExpiry = localStorage.getItem('token_expiry');
        
        if (token && tokenExpiry && Date.now() < parseInt(tokenExpiry)) {
            store.isAuthenticated = true;
            navigate('/dashboard');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await login(username, password);
            const { token, user } = response;
            
            if (!token) {
                throw new Error('No token received from login');
            }

            // Calculate token expiration
            const expirationTime = Date.now() + (TOKEN_EXPIRY_DAYS * 24 * 60 * 60 * 1000);
            
            // Store auth data in localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('token_expiry', expirationTime.toString());
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token_timestamp', Date.now().toString());

            // Update store
            runInAction(() => {
                store.token = token;
                store.user = user;
                store.isAuthenticated = true;
            });

            setSuccessMessage('Login successful!');
            
            // Initialize store
            await store.initialize();
            
            // Navigate to dashboard
            navigate('/dashboard');
            
        } catch (error) {
            console.error('Login error:', error);
            setError(error.message || 'Ein Fehler ist aufgetreten');
            store.clearAuth();
        }
    };

    return (
        <div className="bg-gray-900 w-full text-white">
            <div className="container mx-auto px-6 md:px-8 w-full py-8">
                {/* Logo Section */}
                <div className="mb-8 text-center">
                    <img 
                        src={logo}
                        alt="Kickly Logo" 
                        className="w-40 h-15 mx-auto"
                    />
                </div>

                {/* Login Form Section */}
                <div className="w-full max-w-md mx-auto bg-gray-800 rounded-lg shadow-md p-6 mb-6">
                    <h2 className="text-2xl font-bold text-white mb-4 text-center">Dein ultimativer Kickbase Begleiter</h2>
                    
                    <div className="mb-4 text-center">
                        <p className="text-gray-400">
                            Sicherer Login über die Kickbase API. Der Anmeldetoken wird lokal auf deinem Gerät gespeichert.
                        </p>
                        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                    </div>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <input
                                type="text"
                                inputMode="email"
                                placeholder="Kickbase Email"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="w-full px-4 py-2 rounded-lg bg-gray-700 text-white placeholder-gray-500 border border-gray-600 focus:border-orange-500 focus:outline-none"
                                autoCapitalize="off"
                                autoComplete="email"
                            />
                        </div>
                        <div>
                            <input
                                type="password"
                                inputMode="text"
                                placeholder="Kickbase Passwort"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full px-4 py-2 rounded-lg bg-gray-700 text-white placeholder-gray-500 border border-gray-600 focus:border-orange-500 focus:outline-none"
                                autoComplete="current-password"
                            />
                        </div>
                        <div className="text-center">
                            <button
                                type="submit"
                                className={`w-full px-4 py-2 rounded-lg ${'bg-orange-600 hover:bg-orange-700'} text-white font-semibold focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50`}
                            >
                                {'Einloggen'}
                            </button>
                        </div>
                    </form>
                
                    <p className="text-gray-400 text-xs text-center mt-6">
                        Diese Seite steht in keinerlei Beziehung zu Kickbase.
                    </p>
                </div>

                {/* Features Section */}
                <section className="mt-16 mb-12 text-center">
                    <h2 className="text-3xl font-bold text-white mb-4">Was ist Kickly?</h2>
                    <p className="text-xl text-gray-300 mb-36">
                        Kickly ist dein kostenloses Tool, um mit datengestützten Erkenntnissen 
                        bessere Entscheidungen in Kickbase zu treffen und deine Strategie zu optimieren.
                    </p>
                </section>

                <main>
                    {features.map((feature, index) => (
                        <Feature
                            key={feature.id}
                            img={feature.img}
                            title={feature.title}
                            description={feature.description}
                            index={index}
                        />
                    ))}
                </main>

                {/* Footer Section */}
                <footer className="text-center mt-0 mb-0">
                    <p className="text-gray-400 text-sm">
                        Kickly steht nicht in Verbindung mit Kickbase und ist ein eigenständiges Produkt.
                    </p>
                    <p className="text-gray-400 text-sm mt-2 mb-2">
                        Die auf dieser App verwendeten Bilder gehören der Bundesliga und der Deutschen Fußball Liga und sind nicht mit dieser App assoziiert.
                    </p>
                    <button 
                        onClick={() => setTermsOpen(true)}
                        className="mx-2 px-4 mb-2 py-2 rounded-lg bg-orange-600 text-white font-semibold hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
                    >
                        Datenschutzerklärung
                    </button>
                    <button 
                        onClick={() => setDisclaimerOpen(true)}
                        className="mb-0 px-4 py-2 rounded-lg bg-orange-600 text-white font-semibold hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
                    >
                        Impressum
                    </button>
                </footer>

                {/* Modals */}
                <Modal isOpen={isTermsOpen} onRequestClose={() => setTermsOpen(false)} style={customStyles}>
                    <TermsAndConditions />
                </Modal>

                <Modal isOpen={isDisclaimerOpen} onRequestClose={() => setDisclaimerOpen(false)} style={customStyles}>
                    <Disclaimer />
                </Modal>

                <Modal isOpen={isPrivacyOpen} onRequestClose={() => setPrivacyOpen(false)} style={customStyles}>
                    <PrivacyPolicy />
                </Modal>
            </div>
        </div>
    );
};

export default Login;