import { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { collection, query, where, getDocs, addDoc, deleteDoc, doc, onSnapshot, enableIndexedDbPersistence, getFirestore } from 'firebase/firestore';

export const useTransferVoting = (leagueId) => {
    const [votes, setVotes] = useState({});
    const [loading, setLoading] = useState(true);
    const [userVotes, setUserVotes] = useState({});
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [error, setError] = useState(null);

    // Enable offline persistence
    useEffect(() => {
        const setupPersistence = async () => {
            try {
                await enableIndexedDbPersistence(getFirestore());
                console.log('Offline persistence enabled');
            } catch (err) {
                if (err.code === 'failed-precondition') {
                    console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
                } else if (err.code === 'unimplemented') {
                    console.warn('The current browser does not support offline persistence');
                }
            }
        };
        setupPersistence();
    }, []);

    // Monitor online/offline status
    useEffect(() => {
        const handleOnline = () => {
            console.log('App is online');
            setIsOnline(true);
            setError(null);
        };
        
        const handleOffline = () => {
            console.log('App is offline');
            setIsOnline(false);
            setError('You are currently offline. Changes will be synced when you reconnect.');
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    useEffect(() => {
        if (!leagueId) return;

        let unsubscribe;

        try {
            const votesCollection = collection(firestore, 'leagues', leagueId, 'votes');
            unsubscribe = onSnapshot(
                votesCollection, 
                {
                    next: (snapshot) => {
                        const voteCounts = {};
                        const userVoteMap = {};
                        const userId = localStorage.getItem('kickbaseUserId');

                        snapshot.forEach((doc) => {
                            const vote = doc.data();
                            const transferId = vote.transferId;
                            
                            if (!voteCounts[transferId]) {
                                voteCounts[transferId] = { up: 0, down: 0 };
                            }
                            
                            if (vote.isUpvote) {
                                voteCounts[transferId].up++;
                            } else {
                                voteCounts[transferId].down++;
                            }

                            if (vote.userId === userId) {
                                userVoteMap[transferId] = {
                                    docId: doc.id,
                                    isUpvote: vote.isUpvote
                                };
                            }
                        });

                        setVotes(voteCounts);
                        setUserVotes(userVoteMap);
                        setLoading(false);
                        setError(null);
                    },
                    error: (err) => {
                        console.error('Error in votes listener:', err);
                        setError(err.message);
                        setLoading(false);
                    }
                }
            );

            return () => {
                if (unsubscribe) {
                    unsubscribe();
                }
            };
        } catch (error) {
            console.error('Error setting up votes listener:', error);
            setError(error.message);
            setLoading(false);
        }
    }, [leagueId]);

    const handleVote = async (transferId, isUpvote) => {
        if (!leagueId || !transferId) return;

        const userId = localStorage.getItem('kickbaseUserId');
        if (!userId) {
            setError('No user ID found');
            return;
        }

        if (!isOnline) {
            setError('You are offline. Your vote will be synced when you reconnect.');
        }

        try {
            const votesCollection = collection(firestore, 'leagues', leagueId, 'votes');
            const existingVote = userVotes[transferId];
            
            if (existingVote) {
                if (existingVote.isUpvote === isUpvote) {
                    await deleteDoc(doc(firestore, 'leagues', leagueId, 'votes', existingVote.docId));
                } else {
                    await deleteDoc(doc(firestore, 'leagues', leagueId, 'votes', existingVote.docId));
                    await addDoc(votesCollection, {
                        transferId,
                        userId,
                        isUpvote,
                        timestamp: new Date().toISOString()
                    });
                }
            } else {
                await addDoc(votesCollection, {
                    transferId,
                    userId,
                    isUpvote,
                    timestamp: new Date().toISOString()
                });
            }
            
            setError(null);
        } catch (error) {
            console.error('Error voting:', error);
            setError(error.message);
        }
    };

    return {
        votes,
        userVotes,
        loading,
        error,
        isOnline,
        handleVote
    };
}; 