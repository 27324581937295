import React from 'react';

const LoadingSpinner = ({ size = 'medium' }) => {
    const sizeClasses = {
        small: 'w-6 h-6',
        medium: 'w-8 h-8',
        large: 'w-12 h-12'
    };

    return (
        <div className="flex justify-center items-center">
            <div className={`${sizeClasses[size]} animate-spin`}>
                <div className="h-full w-full border-4 border-gray-700 border-t-orange-500 rounded-full"></div>
            </div>
        </div>
    );
};

export default LoadingSpinner;