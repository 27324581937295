import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { observer } from 'mobx-react-lite';
import { playerStoreInstance } from './stores/PlayerStore';
import Login from './Login';
import Dashboard from './Dashboard';
import { CookieProvider } from './CookieContext';
import PlayerDebugView from './components/PlayerDebugView';
import ErrorBoundary from './components/ErrorBoundary';
import { VoteProvider } from './contexts/VoteContext';
import { notificationService } from './services/notificationService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingState } from './components/LoadingState';
import { onMessageListener } from './firebase';

const App = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const store = playerStoreInstance;
  const [notification, setNotification] = useState({ title: '', body: '' });

  useEffect(() => {
    const initApp = async () => {
      try {
        setIsLoading(true);
        await store.initialize();
        
        // If authenticated, ensure we have a league selected
        if (store.isAuthenticated && store.leagues.length > 0) {
          const storedLeagueId = localStorage.getItem('selectedLeagueId');
          const validLeagueId = storedLeagueId && store.leagues.some(l => l.id === storedLeagueId)
            ? storedLeagueId
            : store.leagues[0].id;
            
          await store.setLeagueId(validLeagueId);
          localStorage.setItem('selectedLeagueId', validLeagueId);
        }
      } catch (error) {
        console.error('App initialization failed:', error);
      } finally {
        setIsLoading(false);
      }
    };
    initApp();
  }, []);

  useEffect(() => {
    let unsubscribeFromMessages = () => {};

    const setupMessaging = async () => {
      try {
        if ('Notification' in window) {
          const permission = await Notification.requestPermission();
          console.log('Notification permission:', permission);
          
          if (permission === 'granted') {
            unsubscribeFromMessages = onMessageListener();
          }
        }
      } catch (error) {
        console.error('Error setting up notifications:', error);
      }
    };

    setupMessaging();

    return () => {
      if (typeof unsubscribeFromMessages === 'function') {
        unsubscribeFromMessages();
      }
    };
  }, []);

  // Add global error handler
  useEffect(() => {
    const handleError = (event) => {
      console.error('Global error:', event.error);
      toast.error('An unexpected error occurred. Please try again.');
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  // Add network status monitoring
  useEffect(() => {
    const handleOffline = () => {
    };

    const handleOnline = () => {
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  if (isLoading) {
    return <div className="flex justify-center items-center min-h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  return (
    <QueryClientProvider client={new QueryClient()}>
      <VoteProvider>
        <Router>
          <div className="bg-gray-900 w-full text-white">
            <CookieProvider>
              <Routes>
                <Route 
                  path="/login" 
                  element={
                    store.isAuthenticated ? 
                      <Navigate to="/dashboard" replace /> : 
                      <Login />
                  } 
                />
                <Route 
                  path="/dashboard/*" 
                  element={
                    store.isAuthenticated ? 
                      <ErrorBoundary>
                        <Dashboard />
                      </ErrorBoundary> : 
                      <Navigate to="/login" replace />
                  } 
                />
                <Route 
                  path="*" 
                  element={
                    <Navigate to={store.isAuthenticated ? "/dashboard" : "/login"} replace />
                  } 
                />
              </Routes>
            </CookieProvider>
          </div>
        </Router>
      </VoteProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
});

export default App;