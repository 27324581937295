import React, { useState, useCallback, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { searchPlayers, addFavoritePlayer } from './api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { usePlayerStore } from './stores/PlayerStore';

const PlayerSearch = ({ onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [players, setPlayers] = useState([]);
    const [favoritedPlayers, setFavoritedPlayers] = useState(new Set());
    const searchInputRef = useRef(null);
    const modalRef = useRef(null);
    const playerStore = usePlayerStore();

    const delayedSearch = useCallback(
        debounce((term) => {
            if (!term) {
                setPlayers([]);
                return;
            }
            searchPlayers(term)
                .then((playersResponse) => {
                    setPlayers(playersResponse.p);
                })
                .catch((error) => {
                    console.error('Failed to search players:', error);
                });
        }, 300),
        []
    );

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        delayedSearch(e.target.value);
    };

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    const handleAddFavorite = async (player) => {
        try {
            const token = localStorage.getItem('token');
            await addFavoritePlayer(player.id, token);
            await playerStore.fetchFavoritePlayers(playerStore.leagueId);
            setFavoritedPlayers(prev => new Set(prev).add(player.id));
        } catch (error) {
            console.error('Failed to add favorite player:', error);
        }
    };

    const translatePosition = (positionNumber) => {
        switch (Number(positionNumber)) {
            case 1: return "Torwart";
            case 2: return "Verteidiger";
            case 3: return "Mittelfeldspieler";
            case 4: return "Stürmer";
            default: return "Unbekannt";
        }
    };

    const renderPlayerCard = (player) => {
        const teamBadgeUrl = `https://kickbase.b-cdn.net/pool/teams/${player.teamId}.png`;
        const fallbackTeamBadgeUrl = `https://kickbase.b-cdn.net/pool/teamsdummies/${player.teamId}.png`;
        const playerImageUrl = `https://kickbase.b-cdn.net/pool/playersbig/${player.id}.png`;
        const isFavorited = favoritedPlayers.has(player.id);

        return (
            <div key={player.id} className="bg-gray-800 p-4 rounded-xl shadow-md mb-4 transition-all hover:shadow-lg hover:scale-102">
                <div className="flex items-center space-x-4">
                    <img
                        src={playerImageUrl}
                        alt={player.name}
                        className="w-16 h-16 rounded-full object-cover"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = fallbackTeamBadgeUrl;
                        }}
                    />
                    <div className="flex-grow">
                        <div className="flex justify-between items-start">
                            <div>
                                <h4 className="text-lg font-bold text-white">{player.firstName} {player.lastName}</h4>
                                <div className="flex items-center mt-1">
                                    <img 
                                        src={teamBadgeUrl}
                                        alt={player.teamName} 
                                        className="w-5 h-5 mr-2 object-cover rounded-full"
                                        onError={(e) => { e.currentTarget.src = fallbackTeamBadgeUrl; }}
                                    />
                                    <p className="text-sm text-gray-400">{player.teamName}</p>
                                </div>
                            </div>
                            <FontAwesomeIcon 
                                icon={isFavorited ? fasStar : farStar}
                                className={`text-xl cursor-pointer ${isFavorited ? 'text-yellow-500' : 'text-gray-400 hover:text-yellow-500'}`}
                                onClick={() => handleAddFavorite(player)}
                                title={isFavorited ? "Added to favorites" : "Add to favorites"}
                            />
                        </div>
                        <div className="mt-2 flex flex-wrap gap-2">
                            <span className="text-xs text-gray-300 py-1 px-2 rounded-full bg-gray-700">
                                {translatePosition(player.position)}
                            </span>
                            <span className="text-xs text-gray-300 py-1 px-2 rounded-full bg-gray-700">
                                Punkteschnitt: {player.averagePoints}
                            </span>
                            <span className="text-xs text-gray-300 py-1 px-2 rounded-full bg-gray-700">
                                Marktwert: {player.marketValue.toLocaleString()} €
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div ref={modalRef} className="bg-gray-900 rounded-lg p-6 w-full max-w-2xl mx-auto shadow-2xl">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold text-white">Player Search</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-white transition-colors"
                    >
                        <FontAwesomeIcon icon={faTimes} size="lg" />
                    </button>
                </div>
                <div className="flex items-center bg-gray-800 rounded-lg mb-4">
                    <FontAwesomeIcon icon={faSearch} className="text-gray-400 ml-3" />
                    <input
                        ref={searchInputRef}
                        type="text"
                        inputMode="search"
                        value={searchTerm}
                        onChange={handleSearch}
                        placeholder="Search players..."
                        className="w-full p-3 bg-transparent text-white focus:outline-none"
                        autoCapitalize="off"
                    />
                </div>
                <div className="overflow-y-auto pr-2 max-h-96" style={{ scrollbarWidth: 'thin', scrollbarColor: '#4B5563 #1F2937' }}>
                    {players.map(renderPlayerCard)}
                </div>
            </div>
        </div>
    );
};

export default PlayerSearch;