import { firebaseApp, messaging, firestore, functions, analytics, VAPID_KEY } from './firebase/config';
import { getToken, onMessage } from 'firebase/messaging';
import { httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

// Log the current environment
const isLocalhost = window.location.hostname === 'localhost';
console.log(`Running on ${isLocalhost ? 'localhost' : 'kickly.de'}`);

// Connect to the emulator in development
if (isLocalhost) {
  try {
    connectFunctionsEmulator(functions, 'localhost', 5001);
    console.log('Connected to Firebase Functions emulator on port 5001');
  } catch (error) {
    console.error('Failed to connect to Functions emulator:', error);
  }
}

export const requestForToken = async () => {
  if (!messaging) {
    console.warn('Messaging not supported in this browser');
    return null;
  }

  try {
    // First, check if we have permission
    if (Notification.permission !== 'granted') {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        console.warn('Notification permission not granted');
        return null;
      }
    }

    // Register service worker
    let swRegistration;
    if ('serviceWorker' in navigator) {
      try {
        // First check if we already have an active service worker
        swRegistration = await navigator.serviceWorker.ready;
        
        // If no active service worker, register a new one
        if (!swRegistration.active) {
          swRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
            scope: '/'
          });
          // Wait for the service worker to be activated
          await new Promise((resolve) => {
            if (swRegistration.active) {
              resolve();
            } else {
              swRegistration.addEventListener('activate', () => resolve());
            }
          });
        }
        
        console.log('Service Worker registered with scope:', swRegistration.scope);
      } catch (error) {
        console.error('Service Worker registration failed:', error);
        // Try registering again without checking existing registration
        swRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
          scope: '/'
        });
      }
    } else {
      console.warn('Service workers are not supported');
      return null;
    }

    // Try to get existing token first
    const existingToken = localStorage.getItem('fcm_token');
    if (existingToken) {
      try {
        // Verify if the existing token is still valid
        const tokenDetails = await messaging.getToken();
        if (tokenDetails === existingToken) {
          console.log('Using existing FCM token');
          return existingToken;
        }
      } catch (error) {
        console.log('Existing token invalid, requesting new token');
      }
    }

    // Get new token
    const currentToken = await getToken(messaging, {
      vapidKey: VAPID_KEY,
      serviceWorkerRegistration: swRegistration
    });
    
    if (currentToken) {
      localStorage.setItem('fcm_token', currentToken);
      console.log('New FCM token obtained:', currentToken.substring(0, 20) + '...');
      return currentToken;
    }
    
    console.warn('No registration token available');
    return null;
  } catch (err) {
    console.error('Error retrieving token:', err);
    throw err;
  }
};

export const scheduleNotification = async (data) => {
  try {
    const isLocalhost = window.location.hostname === 'localhost';
    console.log(`Scheduling notification for ${isLocalhost ? 'localhost' : 'kickly.de'}`);

    // Validate required fields
    if (!data.playerId || !data.playerName || !data.notificationTime || !data.expirationTime) {
      console.error('Missing required fields:', {
        hasPlayerId: !!data.playerId,
        hasPlayerName: !!data.playerName,
        hasNotificationTime: !!data.notificationTime,
        hasExpirationTime: !!data.expirationTime
      });
      throw new Error('Missing required notification data');
    }

    // Always request a fresh token
    const token = await requestForToken();
    if (!token) {
      throw new Error('No FCM token available');
    }

    const kickbaseUserId = localStorage.getItem('kickbaseUserId');
    if (!kickbaseUserId) {
      throw new Error('No Kickbase user ID available');
    }

    // Create the callable function using the functions instance from config
    console.log('Creating callable function schedulePlayerNotificationV2');
    const scheduleFunc = httpsCallable(functions, 'schedulePlayerNotificationV2', {
        timeout: 60000, // 60 second timeout
    });

    // Ensure all numeric values are properly converted and validate timestamps
    const notificationData = {
        ...data,
        playerId: data.playerId.toString(),
        notificationTime: parseInt(data.notificationTime),
        expirationTime: parseInt(data.expirationTime),
        notificationMinutes: parseInt(data.notificationMinutes),
        fcmToken: token,
        kickbaseUserId: kickbaseUserId.toString()
    };

    // Validate timestamps
    const now = Math.floor(Date.now() / 1000);
    if (notificationData.notificationTime <= now) {
        throw new Error('Notification time must be in the future');
    }
    if (notificationData.expirationTime <= notificationData.notificationTime) {
        throw new Error('Expiration time must be after notification time');
    }

    console.log('Scheduling notification with validated data:', {
        ...notificationData,
        fcmToken: token.substring(0, 20) + '...',
        timestamps: {
            now,
            notification: notificationData.notificationTime,
            expiration: notificationData.expirationTime,
            minutesUntilNotification: Math.floor((notificationData.notificationTime - now) / 60),
            minutesUntilExpiration: Math.floor((notificationData.expirationTime - now) / 60)
        }
    });

    try {
        console.log('Calling Cloud Function with data:', {
            ...notificationData,
            fcmToken: '[REDACTED]'
        });

        const result = await scheduleFunc(notificationData);
        console.log('Raw function result:', result);

        if (!result.data?.success) {
            console.error('Function returned unsuccessful result:', result.data);
            throw new Error(result.data?.error || 'Failed to schedule notification');
        }

        return {
            success: true,
            notificationId: result.data.notificationId
        };
    } catch (callError) {
        console.error('Error calling Cloud Function:', {
            error: callError,
            code: callError.code,
            message: callError.message,
            details: callError.details,
            url: window.location.hostname
        });

        // Handle specific error cases
        if (callError.code === 'functions/permission-denied') {
            throw new Error('Unauthorized access. Please check your permissions.');
        }
        
        throw callError;
    }
  } catch (error) {
    console.error('Detailed notification error:', {
      error,
      code: error.code,
      message: error.message,
      details: error.details,
      stack: error.stack,
      url: window.location.hostname === 'localhost' ? 'localhost:3002' : 'kickly.de'
    });
    
    // Handle specific Firebase errors
    if (error.code === 'functions/internal') {
      throw new Error('Server error. Please try again in a few minutes.');
    } else if (error.code === 'functions/invalid-argument') {
      throw new Error('Invalid notification data provided. Please check all fields.');
    } else if (error.code === 'functions/unauthenticated') {
      throw new Error('Authentication required. Please log in again.');
    } else if (error.code === 'functions/not-found') {
      localStorage.removeItem('fcm_token');
      throw new Error('Please try again. Notification token has been refreshed.');
    } else if (error.code === 'functions/resource-exhausted') {
      throw new Error('Too many requests. Please wait a few minutes and try again.');
    } else if (error.code === 'functions/unavailable') {
      throw new Error('Service temporarily unavailable. Please try again later.');
    }
    
    // If it's a custom error we threw earlier, pass it through
    if (error.message) {
      throw error;
    }
    
    // Generic error
    throw new Error('Failed to schedule notification. Please try again later.');
  }
};

export const onMessageListener = () => {
  if (!messaging) {
    return () => {};
  }

  try {
    return onMessage(messaging, (payload) => {
      console.log('Received foreground message:', payload);
      return payload;
    });
  } catch (error) {
    console.error('Error setting up message listener:', error);
    return () => {};
  }
};

export const unsubscribeFromPlayerNotifications = async (playerId) => {
  try {
    const token = await requestForToken();
    if (!token) {
      throw new Error('No FCM token available');
    }

    const unsubscribeFunc = httpsCallable(functions, 'unsubscribeFromPlayerNotifications');
    const result = await unsubscribeFunc({
      playerId,
      fcmToken: token
    });

    if (result.data?.success) {
      localStorage.removeItem(`notification_${playerId}`);
    }
    return result.data;
  } catch (error) {
    console.error('Error unsubscribing from notifications:', error);
    throw error;
  }
};

export { 
  firebaseApp, 
  messaging, 
  firestore, 
  functions,
  analytics 
};
